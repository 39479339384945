var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "item-box" },
    [
      _vm.template && _vm.template["title"]
        ? _c(
            "div",
            { staticClass: "title-box" },
            _vm._l(_vm.template["title"], function (title, titleIndex) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.replaceVal(title.show),
                      expression: "replaceVal(title.show)",
                    },
                  ],
                  key: titleIndex,
                  class: title.class,
                },
                [
                  title.label ? [_vm._v(_vm._s(title.label))] : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.info[title.key]) + " "),
                ],
                2
              )
            }),
            0
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "cnt-div" },
        [
          _vm.template && _vm.template["tag"]
            ? _c(
                "div",
                { staticClass: "tag-box" },
                [
                  _vm._l(_vm.template["tag"], function (tag, tagIndex) {
                    return [
                      tag.show
                        ? _c(
                            "div",
                            {
                              key: tagIndex,
                              staticClass: "tag-item",
                              class: tag.class || "",
                            },
                            [_vm._v(_vm._s(_vm.info[tag.key]))]
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "left",
              style: _vm.showRightBtnBox ? "" : "width: 100%",
            },
            [
              _vm.template && _vm.template["desc"]
                ? _vm._l(
                    _vm.template["desc"].filter(function (item) {
                      return item.class && item.class == "main-desc"
                    }),
                    function (desc, descIndex) {
                      return _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.replaceVal(desc.show),
                              expression: "replaceVal(desc.show)",
                            },
                          ],
                          key: descIndex,
                          staticClass: "desc-box",
                          class: desc.class ? desc.class : "",
                        },
                        [
                          desc.label ? [_vm._v(_vm._s(desc.label))] : _vm._e(),
                          desc.extKey && _vm.info[desc.extKey]
                            ? [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.info[desc.extKey][desc.key]) +
                                    " "
                                ),
                              ]
                            : [_vm._v(" " + _vm._s(_vm.info[desc.key]) + " ")],
                        ],
                        2
                      )
                    }
                  )
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "right btn-box",
              staticStyle: { "margin-top": "0" },
            },
            [
              _vm._l(_vm.btnList, function (act, actIndex) {
                return [
                  ["签到", "取消签到", "重新签到"].includes(act.actName) &&
                  act.show
                    ? _c(
                        "div",
                        {
                          key: actIndex,
                          staticClass: "btn-item",
                          class: act.className,
                          staticStyle: { "margin-left": "0" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.$emit("toOptions", act, _vm.info)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(act.actName) + " ")]
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _vm.template && _vm.template["desc"]
            ? _vm._l(
                _vm.template["desc"].filter(function (item) {
                  return !item.class || item.class != "main-desc"
                }),
                function (desc, descIndex) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.replaceVal(desc.show),
                          expression: "replaceVal(desc.show)",
                        },
                      ],
                      key: descIndex,
                      staticClass: "desc-box",
                      class: desc.class ? desc.class : "",
                    },
                    [
                      desc.label ? [_vm._v(_vm._s(desc.label))] : _vm._e(),
                      desc.extKey && _vm.info[desc.extKey]
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(_vm.info[desc.extKey][desc.key]) +
                                " "
                            ),
                          ]
                        : [
                            _vm._v(
                              " " + _vm._s(_vm.info[desc.key] || "无") + " "
                            ),
                          ],
                    ],
                    2
                  )
                }
              )
            : _vm._e(),
        ],
        2
      ),
      _vm.template && _vm.template["tips"]
        ? _vm._l(_vm.template["tips"], function (tip, tipsIndex) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.replaceVal(tip.show),
                    expression: "replaceVal(tip.show)",
                  },
                ],
                key: tipsIndex,
                staticClass: "tips",
              },
              [_vm._v(_vm._s(_vm.info[tip.key]))]
            )
          })
        : _vm._e(),
      _vm.reloadFile
        ? _vm._l(_vm.fileList, function (file, index) {
            return _c("iFinderFrame", {
              key: index,
              attrs: { uploaderSettings: file },
            })
          })
        : _vm._e(),
      _vm.showBtn && _vm.btnList.length > 0
        ? _c(
            "div",
            { staticClass: "btn-box" },
            [
              _vm._l(_vm.btnList, function (act, actIndex) {
                return [
                  !["签到", "取消签到", "重新签到"].includes(act.actName) &&
                  act.show
                    ? _c(
                        "div",
                        {
                          key: actIndex,
                          staticClass: "btn-item",
                          class: act.className,
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.$emit("toOptions", act, _vm.info)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(act.actName) + " ")]
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }