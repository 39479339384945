<template>
    <div class="item-box">
        <div class="title-box" v-if="template && template['title']">
            <div v-for="(title, titleIndex) in template['title']"
                 v-show="replaceVal(title.show)"
                 :key="titleIndex" :class="title.class">
                <template v-if="title.label">{{ title.label }}</template>
                {{ info[title.key] }}
            </div>
        </div>
        <div class="cnt-div">
            <div class="tag-box" v-if="template && template['tag']">
                <template v-for="(tag,tagIndex) in template['tag']">
                    <div class="tag-item" :class="tag.class || ''" v-if="tag.show" :key="tagIndex">{{ info[tag.key] }}</div>
                </template>
            </div>
            <div class="left" :style="showRightBtnBox ? '' : 'width: 100%' ">
                <template v-if="template &&  template['desc']">
                    <div v-for="(desc, descIndex) in template['desc'].filter(item => item.class && item.class == 'main-desc')"
                         v-show="replaceVal(desc.show)"
                         :key="descIndex" class="desc-box" :class="desc.class ? desc.class : ''">
                        <template v-if="desc.label">{{ desc.label }}</template>
                        <template v-if="desc.extKey && info[desc.extKey]">
                            {{ info[desc.extKey][desc.key] }}
                        </template>
                        <template v-else>
                            {{ info[desc.key] }}
                        </template>
                    </div>
                </template>
            </div>
            <div class="right btn-box" style="margin-top: 0">
                <template v-for="(act,actIndex) in btnList">
                    <div class="btn-item" v-if="['签到','取消签到','重新签到'].includes(act.actName) && act.show"
                         style="margin-left: 0"
                         @click.stop="$emit('toOptions',act,info)"
                         :key="actIndex" :class="act.className">
                        {{ act.actName }}
                    </div>
                </template>
            </div>
            <template v-if="template &&  template['desc']">
                <div v-for="(desc, descIndex) in template['desc'].filter(item => !item.class || item.class != 'main-desc')"
                     v-show="replaceVal(desc.show)"
                     :key="descIndex" class="desc-box" :class="desc.class ? desc.class : ''">
                    <template v-if="desc.label">{{ desc.label }}</template>
                    <template v-if="desc.extKey && info[desc.extKey]">
                        {{ info[desc.extKey][desc.key] }}
                    </template>
                    <template v-else>
                        {{ info[desc.key] || '无' }}
                    </template>
                </div>
            </template>
        </div>
        <template v-if="template && template['tips']">
            <div class="tips" v-for="(tip,tipsIndex) in template['tips']"
                 v-show="replaceVal(tip.show)"
                 :key="tipsIndex">{{ info[tip.key] }}</div>
        </template>
        <template v-if="reloadFile">
            <iFinderFrame v-for="(file, index) in fileList" :key="index" :uploaderSettings="file"></iFinderFrame>
        </template>
        <div class="btn-box" v-if="showBtn && btnList.length > 0">
            <template v-for="(act,actIndex) in btnList">
                <div class="btn-item" v-if="!['签到','取消签到','重新签到'].includes(act.actName) && act.show"
                     @click.stop="$emit('toOptions',act,info)"
                     :key="actIndex" :class="act.className">
                    {{ act.actName }}
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import iFinderFrame from "@/components/iFinderFrame"

export default {
    name: "ListCard",
    components: {
        iFinderFrame
    },
    props: {
        info: Object,
        template: {
            type: Object,
            default: () => {
                return null
            }
        },
        showBtn: {
            type: Boolean,
            default: () => {
                return false
            }
        },
        btnList: {
            type: Array,
            default: () => {
                return []
            }
        },
        tips: {
            type: String,
            default: () => {
                return ''
            }
        },
        fileList: {
            type: Array,
            default: () => {
                return []
            }
        },
        updateFileBox: {
            type: Boolean,
            default: () => {
                return false
            }
        },
    },
    data() {
        return {
            cardInfo: {},
            cardTemplate: {},
            reloadFile: true
        }
    },
    watch: {
        updateFileBox(oldVal,newVal) {
            if(!oldVal && newVal) {
                this.reloadFile = false
                setTimeout(() => {
                    this.reloadFile = true
                }, 50);
            }
        }
    },
    computed: {
        showRightBtnBox() {
            return this.showBtn && this.btnList.filter((item) => {
                if(['签到','取消签到','重新签到'].includes(item.actName) && item.show) {
                    return item
                }
            }).length > 0
        }
    },
    created() {
    },
    mounted() {
    },
    methods: {
        replaceVal(evalStr) {
            if(!evalStr) return true
            let context = this.info
            let val = ''
            try {
                val = eval(evalStr)
            } catch (error) {
                console.log(`${evalStr}_error`,error)
            }
            return val
        },
    }
}
</script>

<style lang="less" scoped>
.item-box {
    position: relative;
    padding-bottom: 0.1rem;
    border-bottom: 1px solid #F5F5F5;
    .title-box {
        display: flex;
        align-items: self-end;
        font-weight: 500;
        .name {
            font-size: 0.16rem;
            color: #13161B;
        }
        .num {
            font-size: 0.12rem;
            color: #909398;
            margin-left: 0.09rem;
        }
    }
    .file-box {
        margin-top: 0.1rem;
    }
    .cnt-div {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        .tag-box {
            width: 100%;
            display: flex;
            align-items: center;
            margin: 0.03rem 0;
            .tag-item {
                height: 0.2rem;
                line-height: 0.2rem;
                text-align: center;
                vertical-align: middle;
                background: var(--opacityColor);
                border-radius: 0.03rem;
                font-size: 0.13rem;
                color: var(--themeColor);
                margin-right: 0.03rem;
                padding: 0 0.03rem;
                box-sizing: border-box;
            }
            .tag-red {
                background: #FFECED;
                color: #FF484D;
            }
        }
        .left {
            width: 75%;
            display: flex;
            flex-wrap: wrap;
        }
        .right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        .desc-box {
            min-width: 50%;
            max-width: 100%;
            font-weight: 500;
            font-size: 0.14rem;
            color: #909398;
        }
        .main-desc {
            color:#13161B;
            width: 100%
        }
        .sub_title{
          width: 100%;
          color: #909398;
          font-size: 0.14rem;
        }
        .pos-right {
            position: absolute;
            top: 0;
            right: 0;
            width: unset !important;
            min-width: unset !important;
            color: #A67C0B;
        }
        .tips {
            color: red;
            width: 100%;
        }
    }
    .tips {
        font-size: 0.12rem;
        font-weight: 500;
        color: #FC3258;
    }
    .btn-box {
        margin-top: 0.05rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .btn-item {
            min-width: 0.6rem;
            height: 0.3rem;
            border-radius: 0.04rem;
            font-size: 0.14rem;
            padding: 0 0.07rem;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.1rem;
        }
        .normal {
            //background: linear-gradient(-55deg, #4479F1, #3875FE);
            //box-shadow: 0px 0.02rem 0.08rem 0px rgba(13, 49, 129, 0.2);
            background: var(--themeColor);
            box-shadow: 0px 0.02rem 0.08rem 0px var(--opacityColor);
            color: #FFFFFF;
        }
        .del {
            color: #90939A;
            background: rgba(255,255,255,0);
            border: 1px solid #DEDEDE;
            border-radius: 0.04rem;
        }
    }
}
</style>
